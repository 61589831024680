<template>

    <Login>
        {{desktopRedirect(!$vuetify.breakpoint.smAndDown)}}
        <template v-slot:mobile-logo>
            <img class="logo mb-8" src="https://cdn.optimit.nl/assets/img/optimit-logo.png"/>
        </template>
        <template v-slot:postlogin>
            <v-row>
                <v-col>
                    <v-divider class="mt-6"> </v-divider>
                </v-col>
                <v-col class="col-auto mt-3">
                    of
                </v-col>
                <v-col>
                    <v-divider class="mt-6"> </v-divider>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col align="center" justify="center">
                    Nog geen account?
                    <router-link to="/register" class="ml-1 d-inline-block text-sm base-link">                            
                        <div class="my-1">                            
                            Meld je hier dan gratis aan!
                        </div>
                    </router-link>
                </v-col>
            </v-row>
        </template>
    </Login>
</template>


<script setup>
    import Login from '@shared/ui/auth/views/Login'
    import url from '@lib/url'
    function desktopRedirect(v) {
        console.log('desktopRedirect',v, process.env.VUE_APP_DESKTOP_VERSION)
        if (!v) {
            return;
        }
        var urlDesktop = process.env.VUE_APP_DESKTOP_VERSION;
        if (!urlDesktop) {
            return;
        }
        url.redirect(urlDesktop);
    }
</script>
