//
// Usage: 
//  import url from '@shared/lib/url'
//  token = url.getParam('token');
//

var url = {};
url.redirect = (link) => {
    window.location.assign(link);
}
url.getParam = (name) => {
    const queryString = window.location.search;    
    const urlParams = new URLSearchParams(queryString);    
    const value = urlParams.get(name);
    
    if (!value) {
        return null;
    }
    return value;
}

export default url;